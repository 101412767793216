import { pay } from "@/api/pay"

export default {
	name: "shoppPayment",
	components: {

	},
	data: () => {
		return {
			cvvCode: "",
			cardNumber: "",
			Month: "",
			Year: "",
			yearOptions: [
				2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032
			],
			radioShow: false,
			address: {},
			order: {},
			shoppProductList: [],
			retailPrice: 0,
			logistics: 0,
			total: 0,
			strCurXSoyego: localStorage.getItem("strCurXSoyego"),
			countrySoyego: localStorage.getItem('countrySoyego'),
			orderNumberSoyego: localStorage.getItem("orderNumberSoyego"),
			payType: localStorage.getItem('paymentMethodSoyego')
		}
	},
	watch: {
		'$route': 'fetchData'
	},
	created() {
		// console.log(localStorage.getItem('paymentMethodSoyego'))
		this.getInit();
	},
	mounted() {
		this.fetchData();
	},
	computed: {

	},
	methods: {
		fetchData() {
			this.$forceUpdate();
		},
		getInit() {
			this.address = JSON.parse(localStorage.getItem('payAddressSoyego'));
			// console.log(this.address)
			let obj = JSON.parse(localStorage.getItem("shoppProductSoyego"));
			this.shoppProductList.push(obj);
			var that = this;
			this.shoppProductList.forEach(item => {
				let price = 0;
				price = (parseFloat(item.discount_price) * item.num).toFixed(2);
				that.retailPrice = (that.retailPrice + parseFloat(price)).toFixed(2);

				let freight = 0;
				freight = item.delivery_money.toFixed(2);
				that.logistics = (that.logistics + parseFloat(freight)).toFixed(2);
			});
			this.total = (parseFloat(this.retailPrice) + parseFloat(this.logistics)).toFixed(2);
		},
		// return_url: encodeURIComponent('http://localhost:8080/web/result'),
		paymentClick() {
			pay({
				out_trade_no: this.orderNumberSoyego,
				pay_type: this.payType,
				return_url: encodeURIComponent('http://localhost:8080/web/shoppComplete')
			}).then(res => {
				// console.log(res)
				if(res.code == 0 && res.data){
					window.location.href = res.data.data;
					// this.$router.push(res.data.data)
				}
			}).catch(err => {
				// console.log(err)
			})
		}
	}
}