<template>
    <div class="shoppPayment">
        <div class="shoppPayment-header">
            <div class="warp">
                <div class="logo">
                    <div class="img" @click="$router.push('/index');">
                        <img src="@/assets/images/goods/soyego.png">
                    </div>
                    <div class="pay">
                        <div class="img"><img src="@/assets/images/goods/Lock.png"></div>
                        <span>SECURE PAYMENT</span>
                    </div>
                </div>
                <div class="text">
                    <span>Continue Shopping</span>
                    <img src="@/assets/images/goods/r.png">
                </div>
            </div>
        </div>
        <div class="shoppPayment-order">
            <div class="warp">
                <div class="order-top">
                    <div class="text complete">
                        <div class="img"><img src="@/assets/images/goods/bag.png"></div>
                        <span>BAG</span>
                    </div>
                    <div class="line complete-line">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="text complete">
                        <div class="img"><img src="@/assets/images/goods/order1.png"></div>
                        <span>Submit Order</span>
                    </div>
                    <div class="line complete-line">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="text ative">
                        <div class="img"><img src="@/assets/images/goods/payment1.png"></div>
                        <span>Payment</span>
                    </div>
                    <div class="line">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="text">
                        <div class="img"><img src="@/assets/images/goods/complete.png"></div>
                        <span>Complete</span>
                    </div>
                </div>
                <div class="order-main">
                    <div class="main-left">
                        <div class="back" @click="$router.go(-1)">
                            <span>&lt;&nbsp;&nbsp;Back</span>
                        </div>
                        <div class="address">
                            <h5>Billing Address</h5>
                            <p>Please make sure your billing address matches the name and address associated with the credit card used for this purchase.</p>
                            <div class="text">
                                <div class="title">
                                    <div>
                                        <span>{{address.name}}</span>
                                        <p>{{address.telephone}}</p>
                                    </div>
                                    <div class="img"><img src="@/assets/images/goods/edit.png"></div>
                                </div>
                                <p>{{address.full_address}}</p>
                            </div>
                        </div>
                        <div class="card">
                            <div class="title">
                                <h5>Credit Card Information</h5>
                                <div class="img"><img src="@/assets/images/goods/safe.png"></div>
                            </div>
                            <div class="mode">
                                <div class="img"><img src="@/assets/images/goods/visa.png"></div>
                                <div class="img"><img src="@/assets/images/goods/mastercard.png"></div>
                                <div class="img"><img src="@/assets/images/goods/amex.png"></div>
                                <div class="img"><img src="@/assets/images/goods/club.png"></div>
                                <div class="img"><img src="@/assets/images/goods/union pay.png"></div>
                                <div class="img"><img src="@/assets/images/goods/jcb.png"></div>
                                <div class="img"><img src="@/assets/images/goods/cb.png"></div>
                                <div class="img"><img src="@/assets/images/goods/InteracLogo.svg.png"></div>
                            </div>
                        </div>
                        <div class="message">
                            <div class="input">
                                <p><span>*</span>Card Number</p>
                                <input type="text" v-model="cardNumber">
                            </div>
                            <div class="select">
                                <p><span>*</span>Expiry Date</p>
                                <div class="text">
                                    <el-select v-model="Month" placeholder="Month">
                                        <el-option v-for="item in 12" :key="item" :label="item" :value="item">
                                        </el-option>
                                    </el-select>
                                    <el-select v-model="Year" placeholder="Year">
                                        <el-option v-for="item in yearOptions" :key="item" :label="item" :value="item">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="input">
                                <p><span>*</span>Cvv Code</p>
                                <input type="text" v-model="cvvCode" placeholder="Number at the back">
                            </div>
                            <div class="radio">
                                <div class="img" v-show="!radioShow" @click="radioShow = true"><img src="@/assets/images/goods/radio.png"></div>
                                <div class="img" v-show="radioShow" @click="radioShow = false"><img src="@/assets/images/goods/radio1.png"></div>
                                <span>Remember this card for later use</span>
                            </div>
                        </div>
                    </div>
                    <div class="main-right">
                        <div class="summary">
                            <h5>Order Summary</h5>
                            <div class="text">
                                <div class="discount">
                                    <span>Order Number</span>
                                    <p>{{orderNumberSoyego}}</p>
                                </div>
                                <div class="discount">
                                    <span>Subtotal</span>
                                    <p>{{strCurXSoyego}}{{retailPrice}}</p>
                                </div>
                                <div class="discount">
                                    <span>Logistics</span>
                                    <p>{{strCurXSoyego}}{{logistics}}</p>
                                </div>
                                <!-- <div class="discountText">
                                    <span>Promo Code</span>
                                    <p>-10%OFF</p>
                                </div>
                                <div class="discountText">
                                    <span>Coupon</span>
                                    <p>-US$10</p>
                                </div> -->
                            </div>
                            <div class="total">
                                <span>Total</span>
                                <p>{{strCurXSoyego}}{{total}}</p>
                            </div>
                            <button @click="paymentClick()">PAY</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "./css/shoppPayment.scss";
</style>

<script>
import shoppPayment from "./js/shoppPayment.js"

export default {
    name: "shoppPayment",
    components: {
    },
    mixins: [shoppPayment]
}
</script>
